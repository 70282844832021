import { FC } from "react";
import { appWithTranslation } from "next-i18next";
import type { AppProps } from "next/app";

import Router from "next/router";
import NProgress from "nprogress"; // nprogress module
import "nprogress/nprogress.css"; // styles of nprogress

import "@styles/ckeditor.scss";
import "@styles/globals.css";

import { DataProvider } from "@context/menu";
import Script from "next/script";

Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

const MyApp: FC<AppProps> = ({ Component, pageProps }) => {
  return (
    <DataProvider>
      {/* Google Analytics Script */}
      <Script
        strategy="afterInteractive"
        src="https://www.googletagmanager.com/gtag/js?id=G-NSZPPKBHJM"
      />
      <Script id="ga-init" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-NSZPPKBHJM', {
            page_path: window.location.pathname,
          });
        `}
      </Script>

      <Component {...pageProps} />
    </DataProvider>
  );
};

export default appWithTranslation(MyApp);
